import React, { useEffect, useState, useRef } from 'react';
import ChatBubble from './ChatBubble';
import OptionButton from './OptionButton';
import serverConf from 'config/server.config';
import ChatSidebar from './ChatSidebar';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import LoadButton from 'components/ui/LoadButton';
import TokenService from 'services/token.service';
import authService from 'services/auth.service';

function Chat({ game }) {
    const [rawResponse, setRawResponse] = useState([]);
    const [messages, setMessages] = useState([]);
    const [options, setOptions] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [autoScroll, setAutoScroll] = useState(false);
    const [lastMessageCount, setLastMessageCount] = useState(0);
    const [token, setToken] = useState(TokenService.getLocalAccessToken());

    const messagesEndRef = useRef(null);
    const scrollContainerRef = useRef(null);

    const [isLastMessageVisible, setIsLastMessageVisible] = useState(true);

    useEffect(() => {
        if (token) {
            authService.checkUser();
        }
    }, []);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const handleScroll = () => {
            if (!scrollContainer) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50; // Adjust threshold as needed
            setIsLastMessageVisible(isAtBottom);
        };

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            // Initialize the state
            handleScroll();
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [messages]);

    useEffect(() => {
        if (game && game._id && messages.length === 0) {
            continueGame();
        }
    }, [game]);

    useEffect(() => {
        if (game && game._id && isSending) {
            continueGame();
        }
    }, [isSending]);

    async function continueGame() {
        try {
            const response = await fetch(`${serverConf.HOST}/ai/genMessage?gameId=${encodeURIComponent(game._id)}`, {
                method: 'POST',
                headers: {
                    'x-access-token': TokenService.getLocalAccessToken(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ game, messages: rawResponse }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let result = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                result += decoder.decode(value, { stream: true });
                const messageObj = decoder.decode(value, { stream: true });
                // Split the buffer by newline characters to get complete JSON objects
                let lines = messageObj.split('linebreak');
                for (let line of lines) {
                    if (!line) continue;
                    const message = JSON.parse(line.trim());
                    // check if message has a prop 'options'
                    if (message.content) {
                        const content = JSON.parse(message.content);
                        if (content.options) {
                            setOptions(content.options);
                        }
                        const rawResponse = message;
                        setRawResponse(prevRawResponse => [...prevRawResponse, rawResponse]);
                    } else {
                        setMessages(prevMessages => [...prevMessages, message]);
                    }
                }
            }
            setIsSending(false);
        } catch (error) {
            console.error('Error:', error);
            setIsSending(false);
        }
    }

    function handleOptionClick(option) {
        const newRawMessage = {
            role: 'user',
            content: option.id.toString() || option.text,
        };
        const newMessage = {
            role: 'user',
            name: 'You',
            text: option.message || option.text,
            type: (option.type === 'narrator' || option.type === 'narration') ? 'narrator' : 'player',
        }
        // Add the new message to the messages array
        //setMessages(prevMessages => [...prevMessages, newMessage]);
        setRawResponse(prevRawResponse => [...prevRawResponse, newRawMessage]);
        setIsSending(true);
        setAutoScroll(true);
        setLastMessageCount(messages.length);
    }

    useEffect(() => {
        console.log('autoScroll:', autoScroll);
        if (messagesEndRef.current && autoScroll) {
            setIsLastMessageVisible(true);
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            if (messages.length > lastMessageCount + 3) {
                setAutoScroll(false);
            }
        } else {
            setIsLastMessageVisible(false);
        }
    }, [messages]);

    useEffect(() => {
        if (!isLastMessageVisible && scrollContainerRef.current) {
            // Scroll down just a little bit
            scrollContainerRef.current.scrollBy(0, 50);
        }
    }, [isLastMessageVisible]);

    const scrollDown = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setAutoScroll(true);
            setLastMessageCount(messages.length);
            setIsLastMessageVisible(true);
        }
    };

    return (
        <div className="flex flex-row items-center justify-center pt-20 pb-10 h-screen max-h-screen h-full bg-gray-50 dark:bg-gray-900">
            {game && game._id && (
                <div className="container flex flex-row h-full min-h-0">
                    <div className="p-4 w-full flex flex-col justify-between overflow-hidden">
                        {/* Parent container with relative positioning */}
                        <div className="relative p-8 w-full mx-auto h-full max-h-60vh mb-4 bg-white dark:bg-gray-800 rounded-lg">
                            {/* Messages container */}
                            <div ref={scrollContainerRef} className="flex flex-col space-y-4 overflow-y-auto hide-scrollbar h-full">
                                {messages.length > 0 &&
                                    messages.map((msg, index) => (
                                        <ChatBubble
                                            key={index}
                                            msg={msg}
                                            game={game}
                                            isSending={isSending && index === messages.length - 1}
                                            isSender={
                                                msg.isSender ||
                                                msg.role === 'user' ||
                                                msg.type === 'player' ||
                                                msg.name === 'You'
                                            }
                                            avatar={
                                                game.characters.find(
                                                    (character) => character.name === msg.name
                                                )?.avatar || '66f47c6dee484acecb5f01d4'
                                            }
                                        />
                                    ))
                                }
                                <div ref={messagesEndRef} className='invisible' />
                            </div>
                            {!isLastMessageVisible &&
                                <LoadButton
                                    onClick={() => scrollDown()}
                                />
                            }
                        </div>
                        {isSending ? (
                            <div className="flex items-center space-x-2 w-full justify-center h-full max-h-40vh">
                                <LoadingSpinner />
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Sending message...
                                </p>
                            </div>
                        ) : (
                            <div className="w-full mx-auto grid h-full max-h-40vh">
                                <div className="h-fit-content w-full">
                                    {options.map((option, index) => (
                                        <OptionButton
                                            key={index}
                                            {...option}
                                            onClick={() => handleOptionClick(option)}
                                            disabled={isSending}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="p-4 w-4/12 hidden md:flex">
                        <ChatSidebar {...game} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Chat;
