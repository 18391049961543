import React, { useState, useEffect } from "react";
import authService from "services/auth.service";

// components
import Navbar from "components/Navbars/AuthNavbar.js";
import backgroundImages from 'assets/img/Cover_dark.webp';
import TilesSection from "components/Tiles/TilesSection";
import GeneratorSection from "components/Generator/GeneratorSection";
import AgePopup from "components/ui/AgePopup";
import TokenService from "services/token.service";

export default function Landing() {

  const [showPopup, setShowPopup] = useState(true);
  const [token, setToken] = useState(TokenService.getLocalAccessToken());

  function closePopup(adult) {
    if (adult === true) {
      setShowPopup(false);
      localStorage.setItem('age', 'adult');
    } else {
      window.location.href = "https://www.google.com";
    }
  }

  useEffect(() => {
    console.log(localStorage.getItem('age'));
    if (localStorage.getItem('age') === 'adult') {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = showPopup ? 'hidden' : 'auto';
  }, [showPopup]);

  useEffect(() => {
    if (token) {
      authService.checkUser();
    }
  }, []);

  return (
    <>
      {showPopup ? (
        <AgePopup closePopup={closePopup} />
      ) : null}
      < Navbar transparent />
      <section>
        <div className="relative pt-16 flex content-center items-center justify-center">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${backgroundImages})`,
              backgroundSize: "cover",
              filter: "brightness(0.4)",
            }}
          ></div>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient flex items-center justify-center"
          >
          </div>

          <div className="container relative mx-auto h-full flex flex-col-reverse md:flex-row justify-center items-center pb-6">
            <div className="px-4 py-24 md:py-0 ml-auto mr-auto text-center h-full flex flex-col items-center justify-center">
              <div className="relative inline-block">
                <h1
                  className="font-semibold text-gray-900 dark:text-white text-7xl"
                  tabIndex="0"
                >
                  Interactive NSFW games with AI CHYOA
                </h1>
                <p className="text-gray-700 dark:text-gray-300 text-2xl mt-6 px-6">
                  Play custom NSFW games with AI CHYOA. Create your own stories and explore your fantasies.
                </p>
              </div>
              <div className='flex flex-row gap-4'>
                <a href="#games">
                  <button
                    type="button"
                    className="mt-8 text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-orange-500 font-medium rounded-lg text-xl px-5 py-2.5 text-center"
                  >
                    PLAY NOW
                  </button>
                </a>
                <a href="#create">
                  <button
                    type="button"
                    className="mt-8 text-white bg-transparent border hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-orange-500 font-medium rounded-lg text-xl px-5 py-2.5 text-center"
                  >
                    CREATE GAME
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GeneratorSection />

      <section className="bg-gray-900 py-8 px-4 antialiased flex align-center justify-center">

        <a href="https://www.wizardofswipe.com" target="_blank">
          <div className="container w-full flex flex-col md:flex-row p-6 bg-transparent border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center">
            <div className="flex flex-col items-start justify-between pr-4">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white text-center md:text-left">
                Want to get more matches on Tinder?
              </h5>
              <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 text-center md:text-left">
                Let the the AI from the Wizard of Swipe manage your Tinder for you.
                <br />
                Use the code RIZZLINES and get a special discount.
              </p>
            </div>
            <button type="button" className="md:ml-10 text-white flex flex-row items-center bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-xl px-5 py-2.5 text-center">
              <img src="/wizardofswipe-nobg.png" alt="logo" className="h-12 mr-4" />
              Wizard of Swipe
            </button>
          </div>
        </a>

      </section>

      <TilesSection />
    </>
  );
}